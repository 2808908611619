@charset "utf-8";
// SCSS Document
//////////// ページ内設定 ////////////
body {
	@extend .bg_main_clear;
	font-size: $fontBase;
	line-height: 1.5;
}

p {
	line-height: 1.5;
}

a {
	color: inherit;
	&:hover,&:focus {
		color: inherit;
		text-decoration: none;
	}
	img:hover {
		@include animationOn;
		opacity: 0.8;
	}
}

.link_default{
	a{
		color: inherit;
		text-decoration: underline;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
dt,
th {
	font-weight: normal;
}
ol{
	list-style-type: none;
}
.prefix {
	@include PropertySetPrefix(background-size, cover); //ベンダープレフィックス自動付与
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(1.0, rgba(0, 0, 0, 0.6)));
	@include ValueSetPrefix(background, linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))); //ベンダープレフィックス自動付与
}


//////////// ボタン設定 ////////////
.hover_action{
	@include animationOn;
	&:hover{
		@extend .bg_main_pale;
	}
}
.hover_action_accent{
	@include animationOn;
	&:hover{
		@extend .bg_accent_clear;
	}
}

.textlink{
	text-decoration: underline;
}

.btn_default {
	@extend .bg_base;
	@extend .font_color_accentdeep;
	@extend .hover_action_accent;
	border: 2px solid $colorAccentDeep;
	border-radius: 30px;
	padding: $spaceXSmall 50px $spaceXSmall $spaceXSmall;
	position: relative;
	text-align: center;
	display: block;
	&:after{
		position: absolute;
		top: 50%;
		margin-top: -10px;
		right: 7%;
		content: '';
		width: 20px;
		height: 20px;
		border: 1px solid  $colorAccentDeep;
		border-width: 1px 1px 0 0 ;
		transform: rotate(45deg);
	}
	&:hover{
		color: #333;
	}
}
input.btn_default{
	padding-right: $spaceXSmall;
}

.btn_counseling{
	@include cv_button;
}
.btn_mail{
	@include cv_button;
	background: $colorAccentDeep;
	&::before{
		background: $colorAccent;
	}
}

.btn_simple {
}


//////////// 汎用クラス ////////////
.display_block { display: block;}
.inline_block { display: inline-block;}
.fit { width: 100%;}
img.fit { width: auto;
max-width: 100%;}
.w_75{width: 75%;}
.float_none { float: none;}
.radius6 { border-radius: 6px;}
.radius_large { border-radius: 12px;}
.radius_true{ border-radius: 30px;}
.flex_arrow{ 
	position: relative;
	margin-bottom: 30px;
	&::after{ @extend .material-icons;
		content: "\E5CF";
		position: absolute;
		right: 50%;
		margin-right: -0.5em;
		font-size: $fontXLarge;
		color: $colorMainDeep;
		bottom: -20px;
	}
}
.fix_arrow{
	position: relative;
	&::after{
		@extend .material-icons;
		@extend .font_color_maindeep;
		font-size: 150%;
		right: -12px;
		position: absolute;
		top: 50%;
		margin: 0;
		content: "\E5CC";
	}
}
.fix_arrow_inset{
	@extend .fix_arrow;
	&::after{
		right: 20px;

	}
}

.table_parent { display: table;}
.table_separate { table-layout: separate; border-spacing: 15px 0;}
.table_cell { display: table-cell; float: none;}

.table_cell_center { display: table-cell; float: none; text-align: center; vertical-align: middle;}

.v_align_middle { vertical-align: middle;}
.v_align_baseline { vertical-align: baseline;}

// bg

.bg_base{ background-color: $colorBase;}
.bg_mono_dark{ background-color: $colorMonoDark;}
.bg_mono_deep{ background-color: $colorMonoDeep;}
.bg_mono{ background-color: $colorMono;}
.bg_mono_light{ background-color: $colorMonoLight;}
.bg_mono_clear{ background-color: $colorMonoClear;}
.bg_main_deep{ background-color: $colorMainDeep;}
.bg_main{ background-color: $colorMain;}
.bg_main_light{ background-color: $colorMainLight;}
.bg_main_pale{ background-color: $colorMainPale;}
.bg_main_clear{ background-color: $colorMainClear;}
.bg_accent{ background-color: $colorAccent;}
.bg_accent_deep{ background-color: $colorAccentDeep;}
.bg_accent_light{ background-color: $colorAccentLight;}
.bg_accent_clear{ background-color: $colorAccentClear;}
.bg_main,.bg_main_deep,.bg_mono_deep,.bg_mono_dark{ color: $colorBase;}

.bg_stripe{
	background-color: $colorBase;
	background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.1, #F9F9F9), color-stop(.1, transparent), to(transparent));
	-webkit-background-size: 10px;
}

.bg_base_opacity{
	background: rgba($colorBase,0.3);
}

//////////// フローティングCVパーツ ////////////


//////////// HEADLINE ////////////
@mixin headlineCircle{
	position: absolute;
	content: '';
	width: $spaceLarge;
	height: $spaceLarge;
	border-radius: 100%;
	@extend .bg_accent;
	@extend .border_base_1;
}

.headline_intro_01{
	@extend .font_serif;
	font-size: 8.1vw;
	@extend .margin_b_large;
	@extend .lh_high;
	span{
		font-size: 4.9vw;
		font-family: inherit;
	}
}

.headline_intro_02{
	@extend .headline_intro_01;
	font-size: 13vw;
	line-height: 1 !important;
	span:first-of-type{
		font-size: 6.9vw;
	}
	span:last-of-type{
		display: inherit;
		font-size: 4.3vw;
	}
}

.headline_wide{
	@extend .bg_accent;
	@extend .text-center;
	@extend .padding_small;
	@extend .font_color_base;
	.frame{
		@extend .display_block;
		@extend .container;
		@extend .padding_none;
		overflow: hidden;
		z-index: 1;
		.inner{
			@extend .border_base_1;
			position: relative;
			padding: $spaceMedium;
			&:before{
				@include headlineCircle;
				left: -($spaceLarge)/2;
				top: -($spaceLarge)/2;
			}
			&:after{
				@include headlineCircle;
				right: -($spaceLarge)/2;
				top: -($spaceLarge)/2;
			}
			span{
				&:before{
				@include headlineCircle;
				bottom: -($spaceLarge)/2;
				left: -($spaceLarge)/2;
				}
				&:after{
					@include headlineCircle;
					right: -($spaceLarge)/2;
					bottom: -($spaceLarge)/2;
				}
			}
		}
	}
	h1,h2,h3,h4,span{
		@extend .font_serif;
		font-size: 22px;
	}
	.comment{
		@extend .font_small;
		padding-top: $spaceXSmall;
	}
}
.headline_liner{
	@extend .padding_b_large;
	@extend .border_main_b1;
	@extend .font_serif;
	@extend .font_mediumlarge;
	@extend .font_color_maindeep;
	@extend .text-center;
}
.headline_liner_accent{
	@extend .headline_liner;
	border-color: $colorAccent !important;
	color: $colorAccent !important;
}

.headline_liner_light{
	@extend .font_base;
	@extend .font_color_accent;
	border-bottom: 5px solid $colorAccent;
	padding-bottom: $spaceXSmall;
}
.headline_min_serif{

}

.headline_cv{
	font-size: 5.9vw;
	line-height: 1.5;
	@extend .font_serif;
	@extend .text-center;
}

.headline_bg_main{
	@extend .font_medium;
	@extend .bg_main_deep;
	@extend .text-center;
	@extend .padding_xsmall;
}
.headline_radius_frame{
	@extend .font_base;
	@extend .radius_true;
	color: #333;
	padding: 10px 15px;

	background: $colorAccentClear;
}


//////////// INDEX ////////////

.top_mainvisual{
	background: url('../img/index/mv_pc.png') no-repeat;
	background-position: -150px;
	background-size: cover;
	position: relative;
	.mvtext{
		padding: 190px 20px 21px;
	}
}

.parts_index_wrap{
	background: url('../img/index/parts_index_bg.png') no-repeat center top;
	padding-top: 440px;
	padding-bottom: 20px;
	position: relative;
	&.wrinkle{
	background: url('../img/pages/parts_index_bg_wrinkle.png?20170512') no-repeat center top;

	}
	[class*="parts_select_"]{
		display: none;
		position: relative;
		z-index: 1;
		margin-bottom: 0;
		&:before{
			position: absolute;
			content: '';
			background: $colorMainDeep;
			width: 12px;
			height: 12px;
			border-radius: 100%;
			z-index: -1;
			
		}
		&:after{
			position: absolute;
			content: '';
			height: 2px;
			background: $colorMainDeep;
			z-index: -1;
		}
	}

	[class*="parts_point_"]{
		position: absolute;
		z-index: 1;
		width: 40px;
		height: 40px;
		border: 1px solid $colorMain;
		border-radius: 100%;
		cursor: pointer;
		background: rgba(255,255,255,0.5);
		@include animationOn;
		&::after{
			content: "";
			position: absolute;
			background: $colorMain;
			width: 20px;
			height: 20px;
			border-radius: 100%;
			top: 9px;
			left: 9px;
			z-index: 1;
		}
		&::before{
			content: "";
			animation: point_action 1s ease -1s infinite;
			display: block;
			position: absolute;
			z-index: -9;
			border-radius: 100%;
			opacity: 0.7;
		}
	}
	.sp_point{
	position: absolute;
	top: 0;
	}
	.parts_point_01{
		left: 50vw;
		margin-left: 11px;
		top: 72px;
	}
	.parts_point_02{
		left: 50vw;
		margin-left: 61px;
		top: 138px;
	}
	.parts_point_03{
		left: 50vw;
		margin-left: 28px;
		top: 237px;
	}
	.parts_point_04{
		left: 50vw;
		margin-left: -104px;
		top: 208px;
	}
	.parts_point_05{
		left: 50vw;
		margin-left: -30px;
		top: 264px;

	}
	.parts_point_06{
		left: 50vw;
		margin-left: -97px;
		top: 132px;
	}
	.parts_point_07{
		left: 50vw;
		margin-left: -63px;
		top: 341px;
	}
}
.prats_single_index{
	[class*="parts_select_"] {
		position: relative;
		.single{
			@extend .bg_main_deep;
			@extend .text-center;
			@extend .font_serif;
			@extend .font_medium;
			@extend .padding_v_small;
			@extend .display_block;
			@include animationOn;
			&:hover{
				@extend .bg_main;
			}
		}
	}
}


@keyframes point_action {
0% {width: 0px; height: 0px; background-color: #fff; top: 19px; left: 19px;}
100% {width: 80px; height: 80px; background-color: $colorAccentLight; opacity:0.04; top: -22px; left: -22px;}

}


[class*="parts_select_"]{
	margin-bottom: $spaceMedium;
	ul{
		border: 2px solid $colorMainDeep;
		li{
			background: $colorBase;
			text-align: center;
			padding-left: 0;
			padding-right: 0;
			a{
				display: block;
				padding-top: $spaceEXSmall;
				padding-bottom: $spaceEXSmall;
				.material-icons{
					color: $colorMainDeep;
				}
				&:hover,&:hover .material-icons{
					background: $colorMainClear;
				}
			}
		}
	}
	li:first-of-type{
		background: $colorMainDeep;
		color: $colorBase;
		padding-top: $spaceEXSmall;
		padding-bottom: $spaceEXSmall;
		border-bottom: 1px solid #fff;
	}
}

.case_wrap{
	@extend .clearfix;
	.case_head{
		@extend .font_serif;
		@extend .font_medium;
		@extend .font_color_base;
		@extend .bg_main_light;
		@extend .padding_xsmall;
		margin: -1px;
		margin-bottom: $spaceSmall;
		span{
			@extend .font_small;
			@extend .inline_block;
			transform: translateY(-18%);
			position: relative;
		}
	}
	.personal{
		@extend .font_medium;
		@extend .margin_b_small;
	}
	.treatment{
		@extend .font_small;
		@extend .margin_b_small;
	}
	.uservoice{
		@extend .bg_stripe;
		@extend .padding_v_small;
		p{
			@extend .font_color_accentdeep;
		}
	}
}

.scroll_feed{
	height: 250px;
	overflow-y: scroll;
	@include original_scroll;
}


//////////// LIST ////////////

.list_treat{
	li{
		@extend .margin_b_small;
		a{
			@extend .hover_action;
			@extend .padding_xsmall;
			@extend .padding_r_medium;
			@extend .display_block;
			@extend .border_mono_1;
		}
		.treat_inject{
			border-left: 10px solid #A981A9;
		}
		.treat_irradiation{
			border-left: 10px solid #94CBDC;
		}
		.treat_surgical{
			border-left: 10px solid #E9AF5B;
		}
	}
}

.list_number{
	list-style: decimal;
	padding-left: 1.5em;
	li{
		@extend .margin_t_small;
	}
}


//////////// CV AREA ////////////
.cv_area {
	.cv{
		box-shadow: 0 0 5px $colorMono;
		background-image: url(../img/common/cv_bg.png);
		@extend .padding_small;
		.inner{
			@extend .bg_base_opacity;
			@extend .padding_small;
			@extend .clearfix;
		}
		.tel{
			@extend .weight_bold; 
			@extend .font_serif;
			@extend .text-nowrap;
			font-size: 10.8vw;
		}
	}
}

/////// 下層ページ　///////

.to_index{
	text-align: center;
	a{
		display: inline-block;
		color: $colorMainLight;
		font-size: 13px;
		padding-top: 30px;
		position: relative;
		&:before,&:after{
			position: absolute;
			content: "";
			z-index: 0;
		}
		&:before{
			width: 25px;
			height: 1px;
			background: $colorMainLight;
			top: 10px;
			left: 50%;
			margin-left: -12px;
		}
		&:after{
			width: 16px;
			height: 16px;
			right: 15px;
			top: 15px;
			left: 50%;
			margin-left: -8px;
			border: 2px solid $colorMainLight;
			border-width: 1px 1px 0 0 ;
			transform: rotate(-45deg);
		}
	}
}

.contents_header{
	background: url(../img/common/under_h1_bg.png) no-repeat center;
	background-size: cover;
	box-shadow:0px 0px 19px -7px #000000;
	height: 100px;
	@extend .padding_v_xlarge;
	h1{
		text-align: center;
		@extend .font_serif;
		@extend .font_large;
		span{
			@extend .font_small;
			display: block;
		}
	}
}


// 下層パーツ
.contents_index{
	@extend .bg_main_deep;
	@extend .padding_t_small;
	@extend .margin_b_xlarge;
	.btn_page_index{
		@include animationOn;
		@extend .margin_b_small;
		font-size: 16px;
		border: 1px solid $colorBase;
		display: block;
		padding: $spaceXSmall 10% $spaceXSmall $spaceXSmall;
		position: relative;
		z-index: 1;
		&:hover{
			@extend .bg_main;
		}
		&:before,&:after{
			position: absolute;
			content: "";
			z-index: 0;
		}
		&:before{
			width: 25px;
			height: 25px;
			background: #fff;
			right: 8px;
			top: 50%;
			margin-top: -10px;
			border-radius: 100%;
		}
		&:after{
			width: 10px;
			height: 10px;
			right: 15px;
			top: 50%;
			margin-top: -4px;
			border: 1px solid $colorMainDeep;
			border-width: 0 1px 1px 0;
			transform: rotate(45deg);
		}
	}
	&_light{
		@extend .contents_index;
		padding-top: 0;
		padding-bottom: $spaceMedium;
		margin: 0;
		background: none;
		.btn_page_index{
			background: $colorBase;
			border-color: $colorMain;
			color: $colorMain;

		}
	}
}

.contents_recommend{
	.inner{
		text-align: center;
		@extend .padding_medium;
		background: $colorBase;
		border: 1px solid $colorMono;
		@extend .margin_b_small;
	}
}

.oparation_lsit{
	border: 1px solid $colorMono;
	@extend .padding_none;
	@extend .bg_mono_light;
	dt,dd{
		border-bottom: 1px solid $colorMono;
		@extend .bg_base;
		&:last-of-type{

		}
	}
	dt{
		text-align: center;
		@extend .bg_accent;
		@extend .font_color_base;
		@extend .padding_small;
		@extend .font_medium;
	}
	dd{
		font-size: $fontMedium;
		@extend .font_color_main;
		a,.false_link{
			padding: 10px;
			padding-right: 30px;
			display: block;
			position: relative;
			&:before,&:after{
				position: absolute;
				content: "";
				z-index: 0;
			}
			&:before{
				width: 25px;
				height: 25px;
				background: #fff;
				right: 8px;
				top: 50%;
				margin-top: -10px;
				border-radius: 100%;
			}
			&:after{
				width: 10px;
				height: 10px;
				right: 15px;
				top: 50%;
				margin-top: -4px;
				border: 1px solid $colorMainDeep;
				border-width: 0 1px 1px 0;
				transform: rotate(45deg);
			}
		}
		.false_link{
			&:after{
				display: none;
			}
		}
	}
}

.oparation_block{
	.oparation_inner{
		@extend .bg_base;
		@extend .padding_small;
		border: 9px solid $colorMainClear;
		hr{
			border-color: $colorAccentClear ;
		}
	}
}

.list_case{
	@extend .clearfix;
	li{
		margin-bottom: $spaceXSmall;
		img{
			text-align: center;
			min-width:100%;
			border: 2px solid $colorMainDeep;
		}
		&:nth-of-type(2n+1){
			position: relative;
			&::after{
				position: absolute;
				content: "\E5CC";
				@extend .material-icons;
				top: 50%;
				font-size: $fontLarge;
				color: $colorMainDeep;
			}
		}
	}
}
.details li{
	@extend .border_mono_dot_b1;
	padding: 10px;
}
.case_second{
	li{
		@extend .col-xs-6;
	}
}
.case_therd{
	li{
		p{
			@extend .font_small;
		}
		@extend .col-xs-4;
		&:nth-of-type(1n+4){
			position: relative;
			&::after{
				position: absolute;
				content: "\E5CC";
				@extend .material-icons;
				top: 50%;
				font-size: $fontLarge;
				color: $colorMainDeep;
			}
		&:nth-of-type(3n){
			&::after{
				content: '';
			}
		}
		}
	}
}

.qanda_block{
	z-index: 1;
	position: relative;
	padding: 40px 30px 40px 30px;
	background: $colorAccentLight;
	&:before{
		position: absolute;
		top: 15px;
		bottom: 15px;
		left: 15px;
		right: 15px;
		content: "";
		@extend .bg_base;
		z-index: -1;
	}

	.ques{
		@extend .border_mono_dot_b1;
		padding-bottom: $spaceSmall;
		@extend .font_medium;
		@extend .font_color_main;
	}
}

.ques,.ans{
	position: relative;
	text-indent: -35px;
	padding-left: 31px !important;
	@extend .margin_b_small;
	&:before{
		@extend .font_serif;
		@extend .font_color_base;
		margin-right: 5px;
		text-indent: 0px;
		content: 'Q';
		font-size: 22px;
		vertical-align: middle;
		text-align: center;
		width: 32px;
		display: inline-block;
		height: 32px;
		@extend .radius_true;
		background: $colorMainLight;
	}
}
.ans:before{
	content: 'A';
	background: $colorAccent;
}

.doctors_voice{
	background: url(../img/pages/treat/doctors_bg.png) no-repeat center;
	background-size: cover;
	@extend .padding_v_medium;
	.doctors_head{
		color: #F8C47F;
		@extend .font_serif;
		font-size: 22px;
		line-height: 1.5;
		margin-bottom: $spaceSmall;
		span{
			font-family: inherit;
		}
	}
	.doctors_head_sub{
		@extend .font_serif;
		color: #CCCCCC;
		margin-bottom: $spaceSmall;
		@extend .font_medium;
		span{
			@extend .font_small;
		}
	}
}

.textwrap_mono_radius{
	@extend .inline_block;
	@extend .radius_true;
	@extend .bg_mono_light;
	@extend .padding_s_medium;
	@extend .padding_v_xsmall;
}
.textwrap_accent_radius{
	@extend .textwrap_mono_radius;
	@extend .bg_accent_clear;
}
.textwrap_main_radius{
	@extend .textwrap_mono_radius;
	@extend .bg_main_clear;
}

// 顔部位

.for_parts_image{
	background: url('../img/pages/treat/for_parts_area_face.png') no-repeat center;
	background-size: cover;
	position: relative;
	@extend .clearfix;
	img{
		top: 0;
		bottom: 0;
		left: 0;
		position: absolute;
	}
	img.dummy{
		position: relative;
	}
}



//////////// TABLE ////////////

.table_default{
	@extend .fit;
	@extend .table;
	@extend .table-bordered;
	th{
		@extend .text-center;
		@extend .bg_main_clear;
		@extend .col-sm-3;
		@extend .col-xs-4;
		float: none;
		font-weight: normal;
	}
}

.table_price{
	width: 100%;
	@extend .font_base;
	border: 1px solid $colorMono;
	th,td{
		padding: 10px;
		font-weight: normal;
		vertical-align: middle;
		@extend .col-xs-6;
	}
	td+td{
		text-align: right;
		border-left: 1px solid $colorMono;
	}
	th{ background: $colorAccentClear;
		@extend .col-sm-6;
		border-bottom: 1px solid $colorMono;
		border-right: 1px solid $colorMono;
		float: none;
		@extend .padding_l_medium;
	}
	th+td{
		text-align: right;
	}
	td{
		text-align: left;
		float: none;
		background: $colorBase;
		@extend .col-sm-6;
		border-bottom: 1px solid $colorMono;
	}
	tr > td:last-of-type{
		@extend .font_medium;
		@extend .font_color_main;
	}
}


@media (min-width: $screen-xs-min) {
	// SPサイズ（横）以上

	.col-sm-4.flex_arrow,
	.col-xs-4.flex_arrow{
		&::after{
			right: -20px;
		}
	}




}

@media (min-width: $screen-sm-min) {
	// タブレットサイズ以上

	body {
		padding-bottom: 0;
	}

	.radius_true{ border-radius: 1000px;}

	.index_philosophy {
		.inner_text {
			padding: 0 $spaceMedium;
		}
	}

	.flex_arrow{
		margin-bottom: 0;
		&::after{
			right: -25px;
			top: 50%;
			margin: 0;
			content: "\E5CC";
		}
	}

	//////////// ボタン設定 ////////////
	.btn_default {
		padding: $spaceSmall 50px $spaceSmall $spaceSmall;
		display: inline-block;
	}
	input.btn_default{
	padding-right: $spaceSmall;
}

	.btn_counseling{
		font-size: 27px;
		
	}
	.btn_mail{
		font-size: 27px;
	}

	//////////// HEADLINE ////////////
	.headline_wide{
		margin-bottom: 50px;
		.frame{
			.inner{
				padding: ($spaceLarge )-5;
			}
		}
		h1,h2,h3,h4,span{
			font-size: $fontLargePc;
		}
	}

	.headline_intro_01,.headline_intro_02{
		font-size: $fontLargePc !important;
		line-height: 1.5 !important;
		span{
			font-size: $fontMediumPc !important;
			display: inline;
		}
	}


	.headline_bg_main{
		padding: $spaceXSmall;
	}

	.headline_cv{
		font-size: 40px;
	}

	//////////// INDEX ////////////

	.top_mainvisual{
		margin: 0 auto;
		box-shadow:0px 0px 19px -7px #000000;
		position: relative;
		height: 480px;
		margin-left: 5%;
		margin-right: 5%;
		.mvtext{
			padding: 182px 5px 0px 10px;
		}
	}


	//////////// CV AREA ////////////
	.cv_area {
		.cv{
			.tel{
				font-size: $fontULargePc;
			}
		}
	}

	.parts_index_wrap{
		background: url('../img/index/parts_index_bg.png') no-repeat center top;
		padding-bottom: 40px;
		padding-top: 0;
		&.wrinkle{
		background: url('../img/pages/parts_index_bg_wrinkle.png?20170512') no-repeat center top;

		}
		[class*="parts_select_"]{
			display: block;
			position: relative;
			z-index: 1;
			margin-bottom: 0;
			&:before{
				position: absolute;
				content: '';
				background: $colorMainDeep;
				width: 12px;
				height: 12px;
				border-radius: 100%;
				z-index: -1;
				
			}
			&:after{
				position: absolute;
				content: '';
				height: 2px;
				background: $colorMainDeep;
				z-index: -1;
			}
		}
				.parts_select_01{ 
			&:before{ right: -157px; top: 83px;}
			&:after{ width: 174px; right:-153px; top: 64px; transform: rotate(14.800000000000001deg); }
		}
		.parts_select_02{ margin-top: -25px;
			&:before{ top: 54px; right: -122px;}
			&:after{ width: 184px; right:-114px; top: 43px; transform: rotate(10deg); }
		}
		.parts_select_03{ margin-top: -25px;
			&:before{ top: 135px; left: -114px;}
			&:after{ width: 163px; left:-125px; top: 86px; transform: rotate(-39.700000000000003deg); }
		}
		.parts_select_04{ margin-top: -25px;
			&:before{ top: 54px; right: -108px;}
			&:after{ width: 118px; right:-100px; top: 48px; transform: rotate(10deg); }
		}
		.parts_select_05{ margin-top: -25px;
			&:before{ top: 107px; left: -165px;}
			&:after{ width: 193px; left:-168px; top: 74px; transform: rotate(-23.699999999999999deg); }
		}
		.parts_select_06{ margin-top: -25px;
			&:before{ top: -8px; right: -84px;}
			&:after{ width: 110px; right:-84px; top: 19px; transform: rotate(-23.300000000000001deg); }
		}
		.parts_select_07{ margin-top: -25px;
			&:before{ top: 78px; left: -93px;}
			&:after{ width: 117px; left:-89px; top: 57px; transform: rotate(-24deg); }
		}
	}
	
	[class*="parts_select_"]{
		margin-bottom: $spaceMedium;
		ul{
			border: 2px solid $colorMainDeep;
			li{
				background: $colorBase;
				text-align: center;
				padding-left: 0;
				padding-right: 0;
				a{
					display: block;
					padding-top: $spaceEXSmall;
					padding-bottom: $spaceEXSmall;
					.material-icons{
						color: $colorMainDeep;
					}
					&:hover,&:hover .material-icons{
						background: $colorMainClear;
					}
				}
			}
		}
		li:first-of-type{
			background: $colorMainDeep;
			color: $colorBase;
			padding-top: $spaceEXSmall;
			padding-bottom: $spaceEXSmall;
			border-bottom: 1px solid #fff;
		}
	}

	.prats_single_index{
		padding-top: 380px;
		[class*="parts_select_"] {
			position: absolute !important;
		}
		.parts_select_01{ top: 1px;}
		.parts_select_02{ top: 0;}
		.parts_select_03{ top: 76px;}
		.parts_select_04{ top: 130px;}
		.parts_select_05{ top: 180px;}
		.parts_select_06{ top: 230px;}
		.parts_select_07{ top: 286px;}
	}

	.contents_header{
		padding-top: $spaceXLarge + 20;
		padding-bottom: $spaceXLarge + 50px;
		position: relative;
		h1{
			font-size: 45px !important;
		}
		&.form{
			padding-bottom: $spaceXLarge;
		}
	}

	.doctors_voice{
		.doctors_head{
			font-size: 55px;
			span{
				font-size: 80%;
			}
		}
		.doctors_head_sub{
			font-size: 32px !important;
		}
	}

//	[class*="col-sm-"].oparation_lsit{
//		& + .oparation_lsit{
//			border-left: none;
//		}
//	}
	[class*="col-sm-"].oparation_lsit:nth-child(even){
		border-left: none;
	}


	//////////// CV AREA ////////////
	.cv {}
}

@media (min-width: $screen-md-min) {
	// PCサイズ（小）以上
	.top_mainvisual{
		background-position: -200px;
		margin-left: 5%;
		margin-right: 5%;
		.mvtext{
			padding: 0;
			position: absolute;
			right: 3%;
			top: 15%;
		}
	}
	.parts_index_wrap{
		.parts_select_01{ 
			&:before{ top: 82px; right: -210px;}
			&:after{ width: 225px; right:-205px; top: 65px; transform: rotate(10.9deg); }
		}
		.parts_select_02{ margin-top: -25px;
			&:before{ top: 51px; right: -176px;}
			&:after{ width: 184px; right:-168px; top: 50px; transform: rotate(3.8deg); }
		}
		.parts_select_03{ margin-top: -25px;
			&:before{ top: 133px; left: -177px;}
			&:after{ width: 236px; left:-181px; top: 81px; transform: rotate(-27.699999999999999deg); }
		}
		.parts_select_04{ margin-top: -25px;
			&:before{ top: 39px; right: -164px;}
			&:after{ width: 173px; right:-157px; top: 39px; transform: rotate(3.3deg); }
		}
		.parts_select_05{ margin-top: -25px;
			&:before{ top: 116px; left: -226px;}
			&:after{ width: 253px; left:-227px; top: 81px; transform: rotate(-18.699999999999999deg); }
		}
		.parts_select_06{ margin-top: -25px;
			&:before{ top: -18px; right: -125px;}
			&:after{ width: 165px; right:-130px; top: 15px; transform: rotate(-21.5deg); }
		}
		.parts_select_07{ margin-top: -25px;
			&:before{ top: 81px; left: -122px;}
			&:after{ width: 149px; left:-123px; top: 61px; transform: rotate(-19.699999999999999deg); }
		}
	}

	.contents_header{
		height: 250px;
	}
	[class*="col-md-"].oparation_lsit{
		& + [class*="col-md-"].oparation_lsit{
			border-left: none;
		}
	}

}

@media (min-width: $screen-lg-min) {
	// PCサイズ（大）以上
	.top_mainvisual{
		background-position: 30%;
		margin-left: 5%;
		margin-right: 5%;
		.mvtext{
			padding: 0;
			position: absolute;
			right: 7%;
			top: 10%;
		}
	}
}
@media (min-width: 1700px) {

	.top_mainvisual{
		background-position: 0;
		margin-left: 10%;
		margin-right: 10%;
		height: 530px;
		.mvtext{
			padding: 0;
			position: absolute;
			right: 7%;
			top: 10%;
		}
	}
}
@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
	// SPサイズ（横）のみ
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	// タブレットサイズのみ
	.headline_cv{
		font-size: 4.3vw;
	}
	.cv_area {
		.cv{
			.tel{
				font-size: 4.8vw;
			}
		}
		.btn_counseling{
		font-size: 3vw;
			
		}
		.btn_mail{
			font-size: 3vw;
		}
	}

	.headline_intro_01,.headline_intro_02{
		font-size: $fontLargePc - 5 !important;
		line-height: 1.5 !important;
		span{
			font-size: $fontMediumPc - 5 !important;
			display: inline;
		}
	}
}

@media (max-width: $screen-xs-max) {
	// SPサイズ（横）以下
}

@media (max-width: $screen-sm-max) {
	// タブレットサイズ以下
}

@media (max-width: $screen-md-max) {
	// PCサイズ（小）以下
}
