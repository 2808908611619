@charset "utf-8";
// SCSS Document

//////////// フッター設定 ////////////

.list_sitemap {
	dt{
		font-weight: bold;
	}
	a {
		color: #fff;
		@extend .font_base;
		display: block;
		@include animationOn;
	}
	& a:hover {
		background: $colorMain;
	}
}