@charset "utf-8";
// SCSS Document

//////////// 文字・フォント関連設定 ////////////

.font_ullarge { font-size: $fontLarge*2;}
.font_xlarge { font-size: $fontXLarge;}
.font_large { font-size: $fontLarge;}
.font_mediumlarge { font-size: $fontMediumLarge;}
.font_medium { font-size: $fontMedium;}
.font_base { font-size: $fontBase;}
.font_small { font-size: $fontSmall;}
.font_xsmall { font-size: $fontXSmall;}

.font_color_base { color: $colorBase;}
.font_color_monodark { color: $colorMonoDark;}
.font_color_monodeep { color: $colorMonoDeep;}
.font_color_mono { color: $colorMono;}
.font_color_monolight { color: $colorMonoLight;}
.font_color_monoclear { color: $colorMonoClear;}
.font_color_maindeep { color: $colorMainDeep;}
.font_color_main { color: $colorMain;}
.font_color_mainlight { color: $colorMainLight;}
.font_color_mainpale { color: $colorMainPale;}
.font_color_mainclear { color: $colorMainClear;}
.font_color_accent { color: $colorAccent;}
.font_color_accentdeep { color: $colorAccentDeep;}
.font_color_accentlight { color: $colorAccentLight;}
.font_color_accentclear { color: $colorAccentClear;}

.font_serif{ font-family: $fontSerif;}
.font_sanserif{ font-family: $fontSansSerif;}

.weight_bold{ font-weight: bold;}

.border_none{ border: none!important;}
.border_main_1{ border: 1px solid $colorMainDeep;}
.border_main_b1{ border-bottom: 1px solid $colorMainDeep;}
.border_main_t1{ border-top: 1px solid $colorMainDeep;}
.border_mono_1{ border: 1px solid $colorMono;}
.border_mono_b1{ border-bottom: 1px solid $colorMono;}
.border_mono_r1{ border-right: 1px solid $colorMono;}
.border_mono_dot_b1{ border-bottom: 1px dashed $colorMono;}
.border_accent_1{ border: 1px solid $colorAccent;}
.border_base_1{ border: 1px solid $colorBase;}
.border_base_b1{ border-bottom: 1px solid $colorBase;}
.border_base_r1{ border-right: 1px solid $colorBase;}
.border_base_dot_b1{ border-bottom: 1px dotted $colorBase;}

.font_tel{
	font-size:36px;
	letter-spacing: -2px
}

.lh_high{
	line-height: 1.4 ;
}

// 上下中央寄せ

// [class*="-12"] .vertical{
// 	position: static;
// 	transform: translateY(0%);
// }

@media (min-width: $screen-sm-min) {

	.font_ularge { font-size: $fontULargePc;}
	.font_xlarge { font-size: $fontXLargePc;}
	.font_large { font-size: $fontLargePc;}
	.font_mediumlarge { font-size: $fontMediumLargePc;}
	.font_medium { font-size: $fontMediumPc;}
	.font_base { font-size: $fontBasePc;}
	.font_small { font-size: $fontSmallPc;}
	.font_xsmall { font-size: $fontXSmallPc;}

	.flex_border_base_r1{ border-right: 1px solid $colorBase;}
	.flex_border_mono_r1{ border-right: 1px solid $colorMonoDeep;}

	// 上下中央寄せ
	.vertical {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
}

@media (max-width: $screen-xs-max) {
	// [class*="col-sm"] , .col-xs-12{
	// 	.vertical {
	// 		position: static;
	// 		transform: translateY(0%);
	// 	}
	// }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	// タブレットサイズのみ
	.font_ularge { font-size: ($fontULargePc)-2;}
	.font_xlarge { font-size: ($fontXLargePc)-2;}
	.font_large { font-size: ($fontLargePc)-2;}
	.font_medium { font-size: ($fontMediumPc)-2;}
}
