/* Sass Document */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src:
        local('Material Icons'),
        local('MaterialIcons-Regular'),
        url(../font/MaterialIcons-Regular.woff2) format('woff2'),
        url(../font/MaterialIcons-Regular.woff) format('woff'),
        url(../font/MaterialIcons-Regular.ttf) format('truetype')
    ;
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 0;
    letter-spacing: normal;
    vertical-align: middle;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    padding-bottom: 0.1em;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
