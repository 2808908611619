@charset "utf-8";
// SCSS Document

//////////// Sass：変数設定 ////////////


// ベンダープレフィックスを自動で付与する
$set-prefix: '' , -moz- , -webkit- , -o- , -ms-;


// カラー
$colorBase: #fff;

$colorMonoDark: #333333;
$colorMonoDeep: #707070;
$colorMono: #CCCCCC;
$colorMonoLight: #F7F7F7;
$colorMonoClear: #F0F0F0;

$colorMainDeep: #EA6A7D;
$colorMain: #ff5d74;
$colorMainLight: #FFA7BA;
$colorMainPale: #fff3f3;
$colorMainClear: #FFF9F9;

$colorAccent: #5BA8BF;
$colorAccentDeep: #037EA3;
$colorAccentLight: #78CAE3;
$colorAccentClear: #E5FCFF;
$colorAccentDark: darken($colorAccent, 15%);
$colorAccentPale: lighten($colorAccent, 15%);

$colorFontBase: #333;

$colorTransLight: rgba(255,255,255,.75);
$colorTransDark: rgba(0,0,0,.75);
$colorTransShadow: 0 1px 5px rgba(0,0,0,.15);


// スペース
$spaceEXSmall: 5px;
$spaceXSmall: 10px;
$spaceSmall: 15px;
$spaceMedium: 20px;
$spaceLarge: 30px;
$spaceXLarge: 60px;


// フォントサイズ
$fontXLarge: 37px;
$fontLarge: 25px;
$fontMediumLarge: 21px;
$fontMedium: 18px;
$fontBase: 15px;
$fontSmall: 12px;
// $fontNotice: 13px;
$fontXSmall: 10px;

$fontULargePc: 48px;
$fontXLargePc: 36px;
$fontLargePc: 32px;
$fontMediumLargePc: 24px;
$fontMediumPc: 20px;
$fontBasePc: 16px;
$fontSmallPc: 13px;
// $fontNoticePc: ;
$fontXSmallPc: 10px;

// フォントファミリー
$fontSerif:  "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", 'Lusitana', "游明朝", YuMincho,"HG明朝E","ＭＳ Ｐ明朝","ＭＳ 明朝",serif;
$fontSansSerif: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;