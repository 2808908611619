@charset "utf-8";
// SCSS Document
//////////// ヘッダー設定 ////////////

/* ヘッダ
----------------------------------------------------------------------------------- */

$SPheaderHeight: 50px;
body{
	margin-top: $SPheaderHeight;
	border-bottom: 70px solid $colorMonoDark;
}

.main_head {
	position: fixed;
	@extend .bg_main_clear;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: $SPheaderHeight;
	border-bottom: 1px solid $colorMain;
	@extend .padding_v_small;
	.head_logo{
		@extend .text-left;
		img{
			height: $SPheaderHeight - 25;
		}
	}

}

.floating_cv{
	position: fixed;
	width: 100%;
	bottom: 0;
	z-index: 999;
	text-align: center;
	border-top: 1px solid $colorMain;
}

@media (min-width: $screen-sm-min) {
	body{
		border: none;
	}
	.floating_cv{display: none;}
}

@media (min-width: $screen-md-min) {

	body{
		margin-top: 0;
		padding-top: 0 !important;
	}

	.main_head { 
		position: relative;
		background: none !important;
		height: inherit;
		border: none;
		.head_logo{
			text-align: center;
			img{
				height: auto;
			}
		}
		
	}

	.head_btn_menu,
	.head_btn_menu_open,
	.head_tel,
	.head_btn,
	 {
		display: none!important;
	}


	.pc_nav{
		position: absolute;
		z-index: 2;
		top: 470px;
		width: 100%;
		text-align: center;
		background: rgba(#fff, 0.6);
		border-top: 1px solid $colorBase;
		border-bottom: 1px solid $colorBase;
		.head_menu{
			width: 970px;
			margin: 0 auto;
			display: table;
			>li{
				display: table-cell;
				position: relative;
				color: $colorMain;
				font-size: 18px;
				z-index: 1;
				&::before{
					@include animationOn;
					content: '';
					left: 0;
					top: 0;
					bottom: 0;
					right: 0;
					position: absolute;
					border-left: 1px solid $colorBase;
					transform: skewX(-10deg);
					z-index:-1;
				}
				&:hover:before{
					background: rgba( $colorMainDeep , 0.2); 
				}
				&::last-of-type{
					border-right: 1px solid $colorBase;
				}
				a{
					display: block;
					padding: $spaceXSmall $spaceSmall;
					&:hover{
						color: inherit;
					}
				}
				&.mail{
					a{ color: #fff;}
					&::before{
						background: rgba( $colorAccent , 0.8);
					}
				}
				&.counseling{
					a{ color: #fff;}
					&::before{
						background: rgba( $colorMainDeep , 0.5);
					}
				}
			}
		}
		&.floating{
			position: fixed;
			background: #fff !important;
			z-index: 99999;
			top: 0;
			bottom: auto!important;
			border-bottom: 1px solid $colorMain;
		}
	}

	.under_nav{
		position: absolute;
		bottom: 0 !important;
		top: auto;
	}
}

@media (min-width: $screen-sm-min) {
	.main_head{
		width: auto;
	}
}

@media (min-width: $screen-md-min) {
}


@media (min-width: 1700px) {

	.main_head{
		width: auto;
		margin-left: 10%;
		margin-right: 10%;
	}
}
