@charset "utf-8";
// SCSS Document

@mixin headBtnMenu {
	position: fixed;
	display: inline-block;
	width: 40px;
	height: 40px;
	top: 5px;
	right: 10px;
	margin: 0;
	font-size: 11px;
	text-align: center;
	z-index: 9999;
}
@mixin headBtnMenuIcon {
	position: absolute;
	content: " ";
	width: 28px;
	height: 3px;
	left: 6px;
	background: $colorMainDeep;
	border-radius: 6px;
	transition: 0.35s;
}
@media (max-width: $screen-sm-max) { // タブレットサイズ以下

	.head_btn_menu {
		@include headBtnMenu;

		+ .head_menu {
			position: fixed;
			top: 51px;
			left: 0;
			bottom: 100%;
			width: 100%;
			padding: 0 20px;
			overflow: scroll;
			background: $colorTransDark;
			transition: 0.35s;
			z-index: 9999;

			li {
				display: block;
				border-top: 1px solid $colorMonoLight;
				a {
					display: block;
					padding: 20px 0;
					color: $colorMonoLight;
					span {
						display: none;
					}
				}
				a.bg_accent {
					background: none;
				}
				&.tel_btn{
					a{
						padding-top: 0;
					}
				}
			}

			.head_sp {
				border: none;
				a {
					margin-bottom: 20px;
					padding: 10px 0;
					color: $colorAccent;
					text-align: center;
					background: $colorMonoLight;
					border-radius: 6px;
				}
				&:nth-of-type(2) {
					a {
						color: $colorMonoLight;
						background-color: $colorAccent;
					}
				}
			}
			.head_tel{
				text-align: center;
				border: none;
				font-size: $fontMediumLarge;
				a{
					font-family: $fontSerif;
					padding-top: 0;					
				}
			}
			.cv_button{
				@include cv_button;
				display: none;	
			}

			.head_entry {
				display: none;
			}
		}

		&::before {
			@include headBtnMenuIcon;
			top: 4px;
		}
		&::after {
			@include headBtnMenuIcon;
			top: 20px;
		}

		span {
			display: inline-block;
			padding: 25px 0 0;
			color: $colorMonoDeep;
			&::after {
				@include headBtnMenuIcon;
				top: 12px;
			}
		}
	}
	.head_btn_menu_open {
		@include headBtnMenu;

		+ .head_menu {
			display: block;
			bottom: 0;
			padding: 20px;
			.cv_button{
				display: block;	
			}
		}

		&::before {
			@include headBtnMenuIcon;
			top: 4px;
			margin-top: 15px;
			transform: rotate(45deg);
			transform-origin: 50% 50%;
		}
		&::after {
			@include headBtnMenuIcon;
			top: 20px;
			top: 19px;
			transform: rotate(-45deg);
		}

		span {
			opacity: 0;
			&::after {
				@include headBtnMenuIcon;
				top: 12px;
			}
		}

	}
	.head_menu_hider {
		position: fixed;
		z-index: 9997;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}


@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { // タブレットサイズのみ

	.main_head {
		.main_logo{
			height: auto;
		}
	}
	.head_btn_menu {
		top: 8px;
		right: 20px;

		+ .head_menu {
			top: $SPheaderHeight;
		}
	}
	.head_btn_menu_open {
		top: 8px;
		right: 20px;
	}
}