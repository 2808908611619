@charset "utf-8";
// SCSS Document

//////////// Sass：ミックスイン設定 ////////////


//// ベンダープレフィックス自動付与
@mixin PropertySetPrefix($name, $value) { // ベンダープレフィックスを自動で付与する
	@each $prefix in $set-prefix {
		#{$prefix}#{$name}: $value;
	}
}
@mixin ValueSetPrefix($name, $value) { // 値にプレフィックスを付与
	@each $prefix in $set-prefix {
		#{$name}: #{$prefix}$value;
	}
}


//// アニメーション
@mixin animationOn {
	@include PropertySetPrefix(transition, 0.35s); //ベンダープレフィックス自動付与
}
@mixin animationOff {
	@include PropertySetPrefix(transition, 0s); //ベンダープレフィックス自動付与
}


@mixin headBtnMenu {
	position: absolute;
	display: inline-block;
	width: 40px;
	height: 40px;
	top: 8px;
	right: 10px;
	margin: 0;
	font-size: 11px;
	text-align: center;
}
@mixin headBtnMenuIcon {
	position: absolute;
	content: " ";
	width: 28px;
	height: 3px;
	left: 6px;
	background: $colorMain;
	border-radius: 6px;
	transition: 0.35s;
}

////コンバージョン用ボタン

@mixin cv_button{
	@extend .padding_v_small;
	@extend .font_serif;
	@extend .font_color_base;
	@include animationOn;
	display: block;
	text-align: center;
	background: #EA6A7D;
	border-radius: 5px;
	position: relative;
	z-index: 99;
	overflow: hidden;
	&::after{
		position: absolute;
		top: 54%;
		margin-top: -10px;
		right: 7%;
		content: '';
		width: 20px;
		height: 20px;
		border: 1px solid #fff;
		border-width: 1px 1px 0 0 ;
		transform: rotate(45deg);
	}
	&::before{
		z-index: -1;
		position: absolute;
		content: '';
		background: #F18DA6;
		top: 0;
		bottom: 40%;
		right: -100px;
		left: -100px;
		border-radius: 0px 0px 100% 100%;
	}
	&:hover{
		color: $colorBase;
		box-shadow: 0 0 3px rgb(0,0,0);
	}
}

@mixin original_scroll{
	&::-webkit-scrollbar{
	overflow:hidden;
	width:5px;
	background:#F38090;
	-webkit-border-radius:3px;
	border-radius:3px;
	}
	&::-webkit-scrollbar:horizontal{
	height:5px;
	}
	&::-webkit-scrollbar-button{
	display:none;
	}
	&::-webkit-scrollbar-piece{
	background:#eee;
	}
	&::-webkit-scrollbar-piece:start{
	background:#eee;
	}
	&::-webkit-scrollbar-thumb{
	overflow:hidden;
	-webkit-border-radius:3px;
	border-radius:3px;
	background: $colorBase;
	}
	&::-webkit-scrollbar-corner{
	overflow:hidden;
	-webkit-border-radius:3px;
	border-radius:3px;
	background: $colorBase;
	}
}