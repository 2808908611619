@charset "utf-8";
// SCSS Document

//////////// リセット設定 ////////////


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summarhtml, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video { margin: 0; padding: 0; border: 0; font-style:normal; font-size: 100%; vertical-align: baseline; font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;}
div, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section { display: block;}
html{ overflow-y: scroll;}
img { vertical-align: bottom;}
blockquote, q { quotes: none;}
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none;}
input, textarea, option, select, button { margin: 0; padding: 0; font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;}
ul{ list-style: none;}
table{ border-collapse: collapse; border-spacing:0;}
caption, th{ text-align: left;}
a:focus { outline:none;}
* {
	word-wrap: break-word;
	word-break: break-all;
}
html {
  box-sizing: border-box;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
}
img { max-width: 100%; height: auto;}
.clearfix:after { content: "."; display: block; clear: both; height: 0; visibility: hidden;}
.clearfix { min-height: 1px;}
* html .clearfix {
    height: 1px;
    /*¥*//*/
    height: auto;
    overflow: hidden;
    /**/
}
.clear{ clear: both;}
