@charset "utf-8";
// SCSS Document

//////////// パンくず設定 ////////////

.breadcrumbs {
	border: none !important;
	overflow: auto;
	@extend .padding_t_small;
	@extend .margin_b_medium;
	ul {
		display: table;
		overflow: visible;
		li {
			display: table-cell;
			padding: $spaceEXSmall 0;
			line-height: 1;
			white-space: nowrap;
			vertical-align: middle;
			font-size: $fontBase;
			font-size: $fontSmall;
			color: $colorMonoDeep;
			a {
				vertical-align: middle;
			}
			&::before {
				margin-right: 5px;
				font-family: 'Material Icons';
				font-size: 20px;
				line-height: 1;
				white-space: nowrap;
				content: "\E5CC";
				color: $colorMonoDeep;
				vertical-align: middle;
			}
			&:first-of-type {
				&::before {
					color: $colorMonoDeep;
					content: "\E88A";
				}
			}
		}
	}
}

