@charset "utf-8";
// SCSS Document

//////////// フォームパーツ設定 ////////////

.form_container{
	.form-group{
		border-bottom: 1px solid $colorMonoClear;
		padding-bottom: $spaceSmall;
		@extend .clearfix;
	}
	label{
		margin-bottom: $spaceSmall;
		font-size: $fontBase;
	}
	.select_group{
		@extend .radius6;
		@extend .border_mono_1;
		margin-bottom: $spaceXSmall;
		overflow: hidden;

		p{
			color: $colorBase;
			background: $colorMonoDeep;
			text-align: center;
		}
		label{
			padding-left: 0;
			background: $colorMonoLight;
			padding: $spaceEXSmall;
			text-align: left;
			width: 100%;
		}
		input[type=checkbox]{
			margin-right: 10px;
			text-align: center;
			height: 20px;
			width: 20px;
		}
		label,input{
			line-height: 150%;
			vertical-align: sub;
			font-weight: normal;

		}
		&:first-of-type{
			input[type=checkbox]{
				margin-right: 10px;
			}
		}
	}
	input[type=text], textarea{
		box-shadow: none;
		border: 1px solid $colorMono;	
	}
	.must{
		@extend .radius6;
		background: $colorMainDeep;
		color: $colorBase;
		font-size: $fontXSmall;
		padding: 0 $spaceXSmall;
	}
	.input_group{
		label{
			margin-right: $spaceSmall;
		}
	}
	.accordion,
	.accord_close {
		//テキスト内のread moreを表示させない
		display: block;
		cursor: pointer;
	}
	.accordion + div{
		display: none;
	}
}
.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.form_flow {
	@extend .padding_v_small;
	@extend .margin_b_large;
	.flow_inner{
		border: 1px solid $colorMono;
		text-align: center;
		position: relative;
		font-size: $fontXSmall;
		@extend .padding_v_xsmall;
		@extend .bg_mono_light;
		@extend .font_color_monodeep;
		&.active{
			@extend .bg_main_deep;
		}
		i{
			position: absolute;
			left: 20px;
			top: 0.9em;
			@extend .font_medium;
		}
	}
	.form_read{
		clear: both;
		@extend .padding_t_small;
	}
	.flex_arrow{
		margin-bottom: 0;
		&::after{
			content: "\E5CC" !important;
			right: -20px;
			top: 50%;
			margin: 0;
			content: "\E5CC";
		}
	}
}

.form_header{
	position: relative;
	margin-top: -45px;
}

.form_header ~ footer{
	margin-bottom: -60px;
	> *{
		display: none;
	}
	> p{
		display: block;
	}
	.form_tel{
		text-align: center;
		a{
			font-size: $fontLarge;
			@extend .font_serif;
			color: $colorMonoDeep!important;
			font-weight: bold;
		}
	}
}
.form_tel{
	display: none;
}

.error-message{
	@extend .alert-danger;
	@extend .padding_s_small;
	clear: both;
}

@media (min-width: $screen-sm-min) {
	.form_container{
		label{
			font-size: $fontBase;
		}
	}
	.form_flow{
		.flow_inner{
			font-size: $fontSmall;
		}
		background: $colorBase!important;
		border-bottom: 1px dotted $colorMono;
	}
	.form_header{
		margin-top: -160px;
	}
	.form_header ~ footer{
		.form_tel , dl{
			display: none;
		}
		section ,section > div{
			display: block!important;	
		}
	}
}

